import React from 'react';
import {  Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/LayoutAlt.js"
import Social from "../components/social.js"
import { FaArrowRight, FaBookReader } from "react-icons/fa"

const HealthFitness = ({ data }) =>{
  
    return(
        
        <Layout>

     <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Your Health is a priority. Wellness, Physical Exercise and a Healthy Attitude?" />
        <title>ReaCH | Health & Fitness</title>
        <link rel="canonical" href="https://reachun.org/health_fitness" />
      </Helmet>

            <section className="sm:w-3/4 sm:ml-1/8 mt-20">
                <h1 className="font-montserrat font-bold text-blue-dark text-3xl sm:text-5xl lg:text-7xl text-center">Health & Fitness</h1>
                <p className="font-opensans text-gray-700 text-justify  mt-4 p-4 sm:p-0">Let those who are sick do all in their power, by correct practice in eating, drinking, and dressing, and by taking judicious exercise, to secure the recovery of health. Let the patients who come to our sanitariums be taught to cooperate with God in seeking health. “Ye are God's husbandry, ye are God's building.” God made nerve and muscle in order that they might be used. It is the inaction of the human machinery that brings suffering and disease. - Diet on Counsel and Foods Ch.17, pg.303</p>

                <div className=" 2xl:w-3/4 2xl:ml-1/8 sm:mt-20 p-4 sm:p-0">
                <div className="">
                  {data.allStrapiHealthFitness.edges.map(health => (
                     <div className="border-b border-gray-200 text-gray-700 pb-16 mt-16" key={health.node.id}>
                         <h1 className="font-montserrat text-3xl sm:text-4xl md:text-5xl text-blue-dark mb-10">{health.node.title}</h1>
                         <img 
                         srcSet={health.node.imgix_path} alt="Health & Fitness" 
                         src={health.node.src_img}
                         className="w-full sm:h-72 lg:h-80 xl:h-96 object-cover " 
                          />
                         <div className="flex xs:flex-wrap  items-center space-x-2 font-opensans text-sm my-4">
                            <img src={health.node.author.avatar.formats.thumbnail.url} alt="Author" className="w-10 h-10 rounded-full border-2"/>
                            <p>{health.node.author.name}</p>
                            <p className="xs:hidden sm:block">|</p>
                            <p>{health.node.date}</p>
                         </div>
                         
                         <p className="font-opensans text-2xl sm:text-3xl w-full md:w-4/5">{health.node.desc}</p>
                         <div className="border-b-8 border-orange-light w-3/25 mt-4"></div>
                         <Link to={`/health_fitness/${health.node.slug}`}>
                         <div className="flex items-center  space-x-2 mt-4 ">
                            <FaArrowRight size="10"/>
                            <FaArrowRight size="15"/>
                            <FaArrowRight size="20"/>
                            <FaBookReader size="40" className="text-gray-700 hover:text-orange-dark cursor-pointer"/>
                         </div>
                         </Link>
                     </div>
                    ))}
                    </div>
                </div>

             </section>
             <Social />
        </Layout>
    )
}

export const query = graphql`
  {
    allStrapiHealthFitness(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          date(formatString: "LL")
          desc
          id
          author {
            name
            avatar {
              formats {
                thumbnail {
                  url
                }
              }
            }
          }
          imgix_path
          src_img
        }
      }
    }
    
  }
`

export default HealthFitness